@import 'Styles/includes';

.Video {
    $root: &;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--IsBackground {
        padding: 0;

        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            height: #{(9 / 16 * 100)}vw;
            width: #{(16 / 9 * 100)}vh;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, -50%);
        }
    }
}
